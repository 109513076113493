import React, { useState } from 'react';
import TimerIcon from '../atoms/icons/TimerIcon';
import LocationPin from '../atoms/icons/LocationPin';
import { formatActivityDateTime } from '../../utils/helpers/activity';
import LinkIcon from '../atoms/icons/LinkIcon';
import PaperIcon from '../atoms/icons/PaperIcon';
import TagIcon from '../atoms/icons/TagIcon';

const ScavengerInfo = ({ huntDetails }) => {
  const { title, description, startTimeUTC, endTimeUTC, price } = huntDetails;
  const [showMore, setShowMore] = useState(false);

  const formattedHuntTime = formatActivityDateTime({
    startDate: startTimeUTC,
    endDate: endTimeUTC,
  });

  return (
    <div className="pt-2 lg:pt-8 px-4 md:px-32">
      <div className="flex-col justify-start items-start inline-flex">
        <div className="mb-[16px] md:mb-[24px] justify-start items-center gap-1.5 inline-flex">
          <div className="text-gray-900 text-[22px] md:text-[44px] font-bold font-['DM Sans'] leading-tight">
            {title}
          </div>
        </div>

        <div className="mb-[12px] md:mb-[24px] flex-col justify-start items-start flex">
          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <TimerIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div className="justify-start items-center gap-1 flex">
              <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                {formattedHuntTime}
              </div>
            </div>
          </div>

          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <LocationPin className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div
              className="cursor-pointer justify-start items-center gap-1 flex"
              onClick={() => {}}
            >
              <a
                href="#poi-map"
                className="text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight"
              >
                {'Uptown Grand Rapids'}
              </a>
            </div>
          </div>

          <div className="justify-start items-center gap-1.5 flex">
            {!!price && (
              <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex mr-4">
                <TagIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
                <div className="justify-start items-center gap-1 flex">
                  <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                    {price}
                  </div>
                </div>
              </div>
            )}

            <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
              <LinkIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
              <a
                className="justify-start items-center gap-1 flex"
                href={'#how-to-play'}
              >
                <div className="underline text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                  more info
                </div>
              </a>
            </div>
          </div>

          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <PaperIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div className="justify-start items-center gap-1 flex">
              <div className="text-gray-900 text-sm md:text-2xl font-bold font-['DM Sans'] leading-tight">
                Description
              </div>
            </div>
          </div>

          <div className="mb-[12px] md:mb-[24px] text-gray-900 text-xs md:text-base font-normal font-['DM Sans']">
            {description}
          </div>

          <div id="how-to-play">
            <div className="justify-start items-center mb-4 md:mb-8">
              <div className="items-center text-gray-900 text-xl md:text-2xl font-bold font-['DM Sans']">
                How to play
              </div>
            </div>
            <div className="mb-[12px] md:mb-[24px] text-gray-900 text-xs md:text-base font-normal font-['DM Sans']">
              <span className="font-bold">
                What do you do once you find an elf?
              </span>
              <br />
              <br />
              First, you’ll laugh. Then, you’ll record the elf’s name on your
              handy dandy elf scavenger hunt player’s card. You can score one of
              these cards at any of the participating businesses. Or you can
              simply print your own –{' '}
              <a
                href={huntDetails.playerCardPdfUrl}
                target="_blank"
                download="Elf Hunt Player Card"
                className="cursor-pointer text-violet-500 underline"
              >
                just download here
              </a>
              .<br />
              Each and every elf has their own sparkly, silly name, and only WE
              know what each of those names are. Your task is to jot down those
              names. Twenty, to be exact. Yep. That means visiting at least
              twenty of our participating businesses. We repeat. No two elves
              are{showMore ? ' named the same!' : '... '}
              {!showMore && (
                <span
                  onClick={() => setShowMore(true)}
                  className="cursor-pointer text-violet-500 underline"
                >
                  {'show more'}
                </span>
              )}
              {showMore && (
                <>
                  {' '}
                  <br />
                  <br />
                  While you’re there, in these small businesses, seeking your
                  elves – well then. You may as well stay awhile, right? Take a
                  look around. Check out what that shop is all about. Scope that
                  restaurant’s menu. Take a moment and see if that establishment
                  offers gift cards. Maybe cross a few things off that holiday
                  gift list while you’re there.
                  <br />
                  <br />
                  Oh! Hey, here’s an even better idea. Use this opportunity to
                  hunt YOUR twenty elves, by choosing businesses on our list
                  that maybe you’ve never been to before. May as well, right?
                  Consider it a two-fer. Snag an elf name…and perhaps find a new
                  Uptown local biz fave, while you’re at it.
                  <br />
                  <br />
                </>
              )}
          

            {showMore && (
              <div>
                The nuts-and-bolts:
                <br />
                <a
                  href={huntDetails.playerCardPdfUrl}
                  target="_blank"
                  download="Elf Hunt Player Card"
                  className="cursor-pointer text-violet-500 text-xs md:text-base font-normal font-['DM Sans'] underline"
                >
                  CLICK HERE
                </a>{' '}
                to download your printable player’s card (or pick one up at any
                of our participating businesses)
                <br />
                <br />
                Turn your player’s card in at Basic Bee Boutique or Rebel
                anytime between now and Shop Hop. Two additional locations are
                available on the day of Shop Hop (7 December) - Five Star Real
                Estate and Hermitage At Diamond.
                <br />
                <br />
                If you want to share about the fun you are having, go for it! Be
                sure to utilize #UptownHolidayHunt2023 and to tag @uptowngr…but
                just be sure not to spill the beans on which elf is hiding
                where. It IS a contest, after all!{' '}
                {showMore && (
                  <span
                    onClick={() => setShowMore(false)}
                    className="cursor-pointer text-violet-500 underline"
                  >
                    {'show less'}
                  </span>
                )}
                <br />
                <br />
                <a
                  target="_blank"
                  href={
                    'https://uptowngr.com/introducing-the-first-annual-uptown-holiday-scavenger-hunt/'
                  }
                  className="cursor-pointer text-violet-500 underline"
                >
                  Visit UptownGR Website
                </a>
              </div>
            )}
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScavengerInfo;
