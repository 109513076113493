import React from 'react';

const TagIcon = ({ className }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.5833 6.41667V6.42583M19.25 6.41667V11C19.25 11.4692 19.071 11.9384 18.713 12.2964L12.2964 18.7131C11.5804 19.429 10.4196 19.429 9.70364 18.7131L3.28697 12.2964C2.57101 11.5804 2.57101 10.4196 3.28697 9.70364L9.70364 3.28697C10.0616 2.92899 10.5308 2.75 11 2.75H15.5833C17.6084 2.75 19.25 4.39162 19.25 6.41667Z"
        stroke="#111827"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagIcon;
