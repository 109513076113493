import React, { useState } from 'react';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import elfHuntData from '../../constants/elf-hunt.json';
import PoiMapDisplay from '../../components/organisms/PoiMapDisplay';
import SpotCard from '../../components/molecules/SpotCard';
import HeroImage from '../../images/scavenger/elf-hunt-desktop.png';
import HeroImageMobile from '../../images/scavenger/elf-hunt-mobile.png';
import ScavengerInfo from '../../components/molecules/ScavengerInfo';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

const huntDetails = {
  title: 'Uptown Holiday Scavenger Hunt',
  description: `Who loves a scavenger hunt? And not just any scavenger hunt. A HOLIDAY scavenger hunt. A hunt, for holiday elves…egregiously, obnoxiously, almost fearsomely cheerful holiday elves. A hunt in which you support local Uptown GR businesses just by playing along. One that is for players of ALL ages – and that enters you to win PRIZES. You do! You do! YOU ALL DO!
  Well, you’re in luck. From the always musing, tinkering and incredibly creative minds of Team Uptown, comes our very first ever district-wide Holiday Elf Scavenger Hunt. And here’s how to get in on the easy-to-play fun.`,
  playerCardPdfUrl:
    'https://drive.google.com/file/d/11OadN5EfjicAoH2twhXzRQ9N4cKkP6MK/view',
  price: 'Free',
  startTimeUTC: '2023-11-26T05:01:00Z',
  endTimeUTC: '2023-12-08T04:59:59Z',
};

const directoryList = [
  'all',
  'east-hills',
  'wealthy',
  'eastown',
  'east-fulton',
];

const mapData = {
  center: [-85.64176266093978, 42.95729652004076],
  zoom: 14,
  maxZoom: 20,
  minZoom: 12,
};

function Scavenger() {
  const [spotToHighlight, setSpotToHighlight] = useState(null);
  const [businessToDisplay, setBusinessToDisplay] = useState(elfHuntData);
  const [directoryFilter, setDirectoryFilter] = useState('all');
  const handleSpotClick = (spot) => {
    setSpotToHighlight(spot);
    // Move focus to map section
    const element = document.getElementById('poi-map');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleBusinessListView = () => {
    document.getElementById('business-list').focus();
  };

  const handleSelectDirectory = (directory) => {
    // Click already selected
    if (directory === directoryFilter) {
      setDirectoryFilter('all');
      setBusinessToDisplay(elfHuntData);
      return;
    }
    // Set Filter and data accordingly
    setDirectoryFilter(directory);
    if (directory === 'all') {
      setBusinessToDisplay(elfHuntData);
    } else {
      const businesses = elfHuntData.filter(
        (biz) => biz.properties.directory === directory,
      );
      setBusinessToDisplay(businesses);
    }
  };

  const navigateToShopHopPage = () => {
    navigate('/festival/shop-hop');
  };

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full py-[60px] md:py-[104px] w-full mb-10"
      customHeaderClass=" "
    >
      <SEO title={huntDetails.title} description={huntDetails.description} />

      <div className="relative w-full">
        <img
          className="w-full h-full object-contain hidden sm:block"
          src={HeroImage}
        />
        <img
          className="w-full h-full object-contain block sm:hidden"
          src={HeroImageMobile}
        />
        <div className="bottom-4 right-3 md:bottom-6 md:right-6 absolute items-center gap-1.5 inline-flex">
          <div
            onClick={navigateToShopHopPage}
            className="cursor-pointer px-3 md:px-4 py-1 bg-dark-grey-gradient rounded-[60px] justify-start items-center gap-1.5 flex"
          >
            <StaticImage
              src="../../images/scavenger/shop-hop-icon.png"
              alt="elf"
              className="drop-shadow-xl w-[24px] md:w-[42px]"
            />
            <div className="text-white text-xs md:text-lg font-medium font-['DM Sans'] leading-tight">
              Uptown Shop Hop
            </div>
          </div>
        </div>
      </div>

      <ScavengerInfo huntDetails={huntDetails} />
      <div className="mt-4 mb-4 px-4 md:px-32 justify-start items-center">
        <div>
          <span className="text-gray-900 text-xl md:text-2xl font-bold font-['DM Sans'] leading-tight">
            Participating Businesses
            {' '}
          </span>
          <br className="block md:hidden" />
          <span className="text-gray-900 text-sm font-normal font-['DM Sans'] leading-tight">
            (sorted in alphabetical order)
          </span>
        </div>
      </div>

      <div className="mb-12 space-x-4 flex flex-nowrap overflow-x-auto scrollbar-hide">
        <div className="w-4 md:w-28" />
        {directoryList.map((directory) => (
          <div
            onClick={() => handleSelectDirectory(directory)}
            key={directory}
            className={`cursor-pointer whitespace-nowrap h-9 px-3 py-2 ${
              directory === directoryFilter ? 'bg-indigo-500' : 'bg-white'
            } rounded-2xl border border-gray-500 border-opacity-20 justify-start items-center gap-1.5 inline-flex`}
          >
            <div
              className={`${
                directory === directoryFilter ? 'text-white' : 'text-gray-900'
              } text-xs md:text-base font-medium font-['DM Sans'] leading-tight`}
            >
              {directory[0].toLocaleUpperCase()
                + directory.replace('-', ' ').slice(1)}
            </div>
          </div>
        ))}
        <div className="w-4 md:w-32" />
      </div>
      <PoiMapDisplay
        poiData={businessToDisplay}
        isVisible
        spotToHighlight={spotToHighlight}
        mapData={mapData}
        handleShowListView={handleBusinessListView}
      />

      <div
        id="business-list"
        className="my-8 mx-12 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4"
      >
        {businessToDisplay.map((poi) => (
          <SpotCard
            key={poi.properties.id}
            isGridView
            spot={poi.properties}
            handleSpotClick={handleSpotClick}
          />
        ))}
      </div>
    </BaseLayout>
  );
}

export default Scavenger;
