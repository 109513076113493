import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { createPoiElement, createPoiPopup } from '../../utils/helpers/mapbox';
import ElfMarkerImg from '../../images/scavenger/elf.png';
import { ClipboardListIcon } from '@heroicons/react/solid';
import NavigateIcon from '../atoms/icons/NavigateIcon';

mapboxgl.accessToken = process.env.GATSBY_MAP_BOX_ACCESS_TOKEN;

function PoiMapDisplay({ spotToHighlight, mapData, poiData, isVisible }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [poiMarkers, setPoiMarkers] = useState([]);
  const handleRecenter = () => {
    map.current.zoomTo(18);
    map.current.flyTo({
      center: mapData.center,
      curve: 0,
    });
  };

  useEffect(() => {
    if (!mapContainer?.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      ...mapData,
      dragPan: true,
      scrollZoom: false,
      doubleClickZoom: true,
    });

    map.current.on('load', () => {
      map.current.addControl(new mapboxgl.NavigationControl());
      const polygonData = {
        type: 'Polygon',
        coordinates: [
          [
            [-85.646628, 42.962964],
            [-85.653833, 42.955511],
            [-85.629576, 42.954543],
            [-85.630472, 42.955625],
            [-85.63256012370205, 42.95722944889172],
            [-85.63577639423134, 42.96270660844577],
            [-85.639885, 42.962899],
          ],
        ],
      };
      map.current.addSource('maine', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: polygonData,
        },
      });

      // Add a new layer to visualize the polygon.
      map.current.addLayer({
        id: 'maine',
        type: 'fill',
        source: 'maine', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#6E3CFF', // blue color fill
          'fill-opacity': 0.5,
        },
      });
    });

    return () => map.current.remove();
  }, [mapContainer]);

  useEffect(() => {
    if (spotToHighlight) {
      map.current.flyTo({
        center: spotToHighlight.longLat,
        curve: 0,
      });

      // Close existing popup in open state
      const popups = document.getElementsByClassName('mapboxgl-popup');
      if (popups.length) {
        popups[0].remove();
      }

      // Zoom to the building level once
      setTimeout(() => map.current.zoomTo(16), 500);

      createPoiPopup(spotToHighlight)
        .setLngLat(spotToHighlight.longLat)
        .addTo(map.current);
    }
  }, [spotToHighlight]);

  useEffect(() => {
    if (!poiData.length) return;

    function renderPointOfInterest() {
      for (const marker of poiMarkers) {
        marker.remove();
      }
      setPoiMarkers([]);

      let newMarkers = [];

      poiData.forEach((poi) => {
        if (poi.properties.longLat.includes('undefined')) return;
        let poiMarker = createPoiElement(poi.properties, ElfMarkerImg);
        const poiPopup = createPoiPopup(poi.properties);
        poiMarker = new mapboxgl.Marker(poiMarker, { anchor: 'bottom' })
          .setLngLat(poi.properties.longLat)
          .setPopup(poiPopup)
          .addTo(map.current);

        newMarkers.push(poiMarker);
      });
      setPoiMarkers(newMarkers);
    }
    renderPointOfInterest();
  }, [poiData]);

  if (!isVisible) return null;
  return (
    <div className="relative h-full">
      <div className="bg-secondary-red h-[600px] w-full shadow-md">
        <div
          id="poi-map"
          ref={mapContainer}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        />
      </div>
      <div className="absolute bottom-8 md:bottom-12 right-2 md:right-6 lg:right-10 z-50">
        <button
          className="mb-2 md:mb-4 h-10 w-10 md:h-12 md:w-12 shadow-md bg-slate-700 text-white flex rounded-full items-center justify-center transition"
          onClick={handleRecenter}
        >
          <NavigateIcon className="text-white h-4 w-4 md:h-6 md:w-6" />
        </button>
        <a
          className="h-10 w-10 md:h-12 md:w-12 shadow-md bg-slate-700 text-white flex rounded-full items-center justify-center transition"
          href="#business-list"
        >
          <ClipboardListIcon className="text-white h-7 w-7 md:h-8 md:w-8" />
        </a>
      </div>
    </div>
  );
}

export default PoiMapDisplay;
