export const getAnchor = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/[ ]/g, '-');
};

// format 'type' data on twin/business data model
export const formatTwinType = (type) => type.replace('_', ' ').split(' ')[0];

export const formatEmojiFilename = (str) => {
  // remove "-emoji" from the end of the string
  str = str.replace(/-emoji$/, '');

  // replace hyphens with spaces
  str = str.replace(/-/g, ' ');

  // capitalize all words in the string
  str = str.replace(/\b\w/g, (l) => l.toUpperCase());

  return str;
};

export const stringConcatenator = (strings, connector) => {
  if (strings?.length) {
    return strings.reduce((previousResult, current) => {
      // both present
      if (previousResult?.length && current?.length) {
        return `${previousResult}${connector}${current}`;
      }
      // only current present
      if (!previousResult?.length && current?.length) {
        return current;
      }
      // only previous present
      if (previousResult?.length && !current?.length) {
        return previousResult;
      }
      return '';
    });
  } else {
    return '';
  }
};

function decodeURIComponentSafe(encodedString) {
  try {
    return decodeURIComponent(encodedString);
  } catch (error) {
    return encodedString;
  }
}

export const convertEncodedString = (inputString) => {
  return inputString.replace(/%/g, decodeURIComponentSafe);
};
