import React from 'react';
import ChevronRight from '../atoms/icons/ChevronRight';
import LocationPin from '../atoms/icons/LocationPin';
import { stringConcatenator } from '../../utils/helpers/strings';

const SpotCard = ({ spot, isGridView, handleSpotClick }) => {
  const {
    db_ref: dbRefId,
    name,
    address,
    house_number: houseNumber,
    street,
    city,
  } = spot;

  const formattedAddress =
    address || stringConcatenator([houseNumber, street, city], ', ');
  return (
    <div
      key={dbRefId}
      className={`${
        isGridView
          ? ''
          : 'min-w-[280px] md:min-w-[360px] max-w-[280px] md:max-w-[360px]'
      } cursor-pointer p-4 bg-grey-gradient rounded-2xl flex-col gap-3 flex justify-around`}
      onClick={() => handleSpotClick(spot)}
    >
      {/** First Row */}
      <div className="w-100 flex inline-flex justify-between">
        <div className="text-gray-900 text-base md:text-xl font-bold font-['DM Sans'] leading-tight">
          {name}
        </div>
        <div>
          <ChevronRight className="w-4 h-4 md:w-6 md:h-6" />
        </div>
      </div>

      {/** Second Row */}
      {!!formattedAddress?.length && (
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="justify-start items-center gap-1.5 flex">
            <LocationPin
              className="w-4 h-4 md:w-[26px] md:h-[26px]"
              strokeColor={'#191A25'}
            />
            <div className="justify-start items-center gap-1 flex">
              <div
                className={`text-slate-800 text-xs md:text-base lg:text-lg font-normal font-['DM Sans']`}
              >
                {formattedAddress}
              </div>
            </div>
          </div>
        </div>
      )}
      {/** Third Row */}
      <div className="ml-3 justify-start items-start -gap-3 inline-flex">
        {spot.communities?.map((community) => (
          <div className="justify-center items-center flex -ml-[7px] -md:ml-3">
            <img
              src={community.image_url}
              alt={community.name}
              title={community.name}
              className="w-[35px] h-[35px] md:w-12 md:h-12 object-cover object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpotCard;
