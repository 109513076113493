import React from 'react';

const NavigateIcon = ({ className, strokeColor = '#ffffff' }) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.49983 0.735369L4.37044 7.41128L3.55448 4.97522L3.47508 4.73816L3.2378 4.65945L0.796431 3.84959L7.49983 0.735369Z"
        fill={strokeColor}
        stroke={strokeColor}
      />
    </svg>
  );
};

export default NavigateIcon;
